<template>
  <v-bottom-navigation
    background-color="#0A5B8A"
    max-height="56"
    height="56"
    elevation="0"
    fixed
    style="z-index:10000;"
    v-if="profile._id"
  >
    <div class="d-flex w-100">
      <v-tabs
        optional
        color="#B5E539"
        background-color="transparent"
        class="bottom-navigation hidden-sm-and-up"
        @change="onSelected"
        v-model="selectedMenu"
        grow
      >
        <v-tab v-for="item in menus" :key="item.title">
          <bottom-navigation-menu-icon
            :icon="item.title"
            :selected="checkSelected(item)"
          />
        </v-tab>
      </v-tabs>
      <div class="text-center" v-if="isAuthenticated">
        <v-menu offset-y left top rounded="lg">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="d-flex flex-row"
              style="height: 56px"
            >
              <v-btn icon>
                <v-icon color="white">
                  mdi-menu
                </v-icon>
              </v-btn>
            </div>
          </template>
          <v-list class="pa-0">
            <bottom-navigation-menu />
          </v-list>
        </v-menu>
      </div>
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-bottom-navigation>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import BottomNavigationMenu from "./BottomNavigationMenu.vue";
import BottomNavigationMenuIcon from "./common/BottomNavigationMenuIcon.vue";
export default {
  components: {
    BottomNavigationMenu,
    BottomNavigationMenuIcon,
  },
  data() {
    return {
      menus: [
        {
          title: "home",
          router: "home",
          path: "/",
        },
        {
          title: "needs",
          router: "agency-need-feeds",
          path: "/needs/feeds",
        },
        {
          title: "chat",
          router: "mobile-chat",
          path: "/mobile-chat",
        },
        {
          title: "notifications",
          router: "notifications",
          path: "/notifications",
        },
      ],
      placeholderImage: require("../assets/default.jpg"),
      selectedMenu: -1,
      searchKey: "",
      recentSearches: null,
      searchDropdown: false,
      snackbar: false,
      errorMessage: null,
    };
  },
  methods: {
    ...mapActions({
      onLogout: "auth/onLogout",
      fetchProfile: "auth/fetchProfile",
      initConversationsClient: "chat/initConversationsClient",
      getSearchHistory: "search/getSearchHistory",
      getSearchContents: "search/getSearchContents",
      deleteKey: "search/deleteKey",
    }),
    onSelected(index) {
      if (index != undefined) {
        if (this.$route.name && this.$route.name != this.menus[index].router) {
          this.$router.push({ name: this.menus[index].router });
        }
      } else {
        setTimeout(() => {
          for (var i = 0; i < this.menus.length; i++) {
            if (
              this.menus[i].router == this.$route.name ||
              this.menus[i].path == this.$route.matched[0].path
            ) {
              this.selectedMenu = i;
              break;
            }
          }
        }, 300);
      }
    },
    onBack() {
      this.$router.push({ name: "home" });
    },
    checkSelected(item) {
      return item === this.menus[this.selectedMenu];
    },
    getSelected() {
      this.selectedMenu = -1;
      if (
        this.$route.name == "volunteer-home" ||
        (this.$route.matched[0] &&
          this.$route.matched[0].path == "/volunteer/home") ||
        this.$route.name == "agency-home" ||
        (this.$route.matched[0] &&
          this.$route.matched[0].path == "/agency/home")
      ) {
        this.selectedMenu = 0;
        return;
      } else if (
        this.$route.name == "volunteer-need-feeds" ||
        (this.$route.matched[0] &&
          this.$route.matched[0].path == "/volunteer/needs") ||
        this.$route.name == "agency-need-feeds" ||
        (this.$route.matched[0] &&
          this.$route.matched[0].path == "/agency/needs")
      ) {
        this.selectedMenu = 1;
        return;
      }
      for (var i = 0; i < this.menus.length; i++) {
        if (
          this.menus[i].router == this.$route.name ||
          (this.$route.matched[0] &&
            this.menus[i].path == this.$route.matched[0].path)
        ) {
          this.selectedMenu = i;
          break;
        }
      }
    },
    onSearch() {
      if (this.$route.name == "search") {
        if (this.$route.query.q != this.searchKey) {
          this.$router.replace({
            query: {
              q: this.searchKey,
              category: "All",
            },
          });
          this.searchDropdown = false;
        }
      } else {
        this.$router.push({ name: "search", query: { q: this.searchKey } });
      }
    },
    onSelectKey(key) {
      this.searchKey = key;
      this.searchDropdown = false;
      this.onSearch();
    },
    onDeleteKey(key) {
      this.deleteKey({ _id: key._id })
        .then((res) => {
          this.recentSearches = this.recentSearches.filter(
            (r) => r._id != res._id
          );
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
  },
  computed: {
    ...mapGetters("auth", {
      isAuthenticated: "isAuthenticated",
    }),
    ...mapState("auth", {
      profile: "profile",
      profileType: "type",
    }),
  },
  watch: {
    "$route.name": function(newValue) {
      this.getSelected();
      this.searchKey = "";
      if (newValue == "search") {
        this.searchKey = this.$route.query.q;
      }
    },
    searchDropdown(newValue) {
      if (newValue) {
        this.getSearchHistory()
          .then((res) => {
            this.recentSearches = res;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.getSelected();
    this.loading = true;
    if (this.$vuetify.breakpoint.xs) {
      this.fetchProfile()
        .then(() => {
          this.loading = false;
          this.initConversationsClient();
        })
        .catch((error) => {
          this.loading = false;
          if (
            error == "User not found" ||
            error == "Permission denied" ||
            error == "Agency not found" ||
            error == "Admin not found"
          ) {
            this.snackbar = true;
            this.errorMessage =
              "Your account has been deleted or something went wrong. Please login again!";
            this.onLogout().then(() => {
              this.$router.push({ name: "landing" });
            });
          } else if (
            error == "Failed to authenticate token." ||
            error == "Invalid token."
          ) {
            this.snackbar = true;
            this.errorMessage =
              "Your token has some problem, please login again";
            this.onLogout().then(() => {
              this.$router.push({ name: "landing" });
            });
          }
        });
    }
    if (this.$route.name == "search") {
      this.searchKey = this.$route.query.q;
    }
  },
};
</script>
<style scoped>
.list-item:hover {
  background: #eef3f8;
}
.bottom-navigation :deep(.v-tab) {
  min-width: 48px;
}
</style>
  