import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    blogs: [],
  },
  getters: {
    getAllBlogs(state) {
      return state.blogs;
    },
  },
  mutations: {
    setBlogs(state, data) {
      state.blogs = data;
    },
    addBlog(state, data) {
      const item = state.blogs.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.blogs.unshift(data);
      }
    },
    editBlog(state, data) {
      const item = state.blogs.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.blogs.push(data);
      }
    },
    deleteBlog(state, data) {
      state.blogs = state.blogs.filter((q) => q._id !== data._id);
    },
  },
  actions: {
    getSearchContents({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("search/contents", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getSearchHistory({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("search/history", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteKey({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("search/delete", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteAllKeys({ rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("search/delete-all")
          .then((res) => {
            if (res.data.success == true) {
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
