import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    events: [],
    userEvents: [],
    playing: "",
    eventCommentLoaded: false,
  },
  getters: {
    getAllEvents(state) {
      return state.events;
    },
    getUserEvents(state) {
      return state.userEvents;
    },
    playing(state) {
      return state.playing;
    },
    eventCommentLoaded(state) {
      return state.eventCommentLoaded;
    },
  },
  mutations: {
    setEvents(state, data) {
      state.events = data;
    },
    setUserEvents(state, data) {
      state.userEvents = data;
    },
    loadMoreEvents(state, data) {
      data.map((d) => {
        if (state.events.filter((e) => e._id == d._id).length == 0) {
          state.events.push(d);
        }
      });
    },
    loadMoreUserEvents(state, data) {
      data.map((d) => {
        if (state.userEvents.indexOf(d) == -1) state.userEvents.push(d);
      });
    },
    addEvent(state, data) {
      const item = state.events.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.events.unshift(data);
      }
      const itemAgency = state.userEvents.find((item) => item._id === data._id);
      if (itemAgency) {
        Object.assign(itemAgency, data);
      } else {
        state.userEvents.unshift(data);
      }
    },
    editEvent(state, data) {
      const item = state.events.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.events.push(data);
      }
      const itemAgency = state.userEvents.find((item) => item._id === data._id);
      if (itemAgency) {
        Object.assign(itemAgency, data);
      } else {
        state.userEvents.push(data);
      }
    },
    deleteEvent(state, data) {
      state.events = state.events.filter((q) => q._id !== data._id);
      state.userEvents = state.userEvents.filter((q) => q._id !== data._id);
    },
    setPlaying(state, data) {
      state.playing = data;
    },
    setEventCommentLoaded(state, data) {
      state.eventCommentLoaded = data;
    },
  },
  actions: {
    fetchAllEvents({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/all")
          .then((res) => {
            if (res.data.success == true) {
              commit("setEvents", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchUserEvents({ commit, rootState }, _id) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/agency", { _id })
          .then((res) => {
            if (res.data.success == true) {
              commit("setUserEvents", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getReportedEvents({ rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("admin/reported-events")
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    resolveReportedEvent({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("admin/resolve-reported-event", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loadMoreEvents({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/load-more", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("loadMoreEvents", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loadMoreUserEvents({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/load-more-agency", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("loadMoreEvents", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getEvent({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/get", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addEvent({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token, 0)
          .post("event/add", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("addEvent", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editEvent({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/update", params, { Authorization: rootState.auth.token })
          .then((res) => {
            if (res.data.success == true) {
              commit("editEvent", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteEvent({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/delete", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteEvent", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setPlaying({ commit }, param) {
      commit("setPlaying", param);
    },
    setEventCommentLoaded({ commit }, param) {
      commit("setEventCommentLoaded", param);
    },
    getLikeCount({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/like/get-count", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    like({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/like", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getReactions({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/reactions", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    report({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/report", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    block({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/block", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    comment({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/comment", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editComment({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/edit-comment", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getComments({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/get-comments", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loadMoreComments({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/load-more-comments", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    reportComment({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/report-comment", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteComment({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/delete-comment", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCommentLikeCount({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/comment-like/get-count", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    commentLike({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/comment-like", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCommentReactions({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("event/comment-reactions", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
