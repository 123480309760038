import axiosInstance from "@/api/axios";
import axios from "axios";

export default {
  namespaced: true,
  state: {
    needs: [],
    apiKey: "AIzaSyB94toBjU5Ne7fz3xfjjS1PsgwaCabFKXg",
  },
  getters: {
    getAllNeeds(state) {
      return state.needs;
    },
  },
  mutations: {
    setNeeds(state, data) {
      state.needs = data;
    },
    addNeed(state, data) {
      const item = state.needs.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.needs.unshift(data);
      }
    },
    editNeed(state, data) {
      const item = state.needs.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.needs.push(data);
      }
    },
    deleteNeed(state, data) {
      state.needs = state.needs.filter((q) => q._id !== data._id);
    },
  },
  actions: {
    fetchAllNeeds({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("need/all", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("setNeeds", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchAgencyNeeds({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("need/agency", { _id: params })
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchVolunteerNeeds({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("need/volunteer", { _id: params })
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchDateNeeds({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("need/date", { date: params })
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchMonthNeeds({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("need/month", { month: params })
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getNeedDetails({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("need/get", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("addNeed", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addNeed({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("need/add", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("addNeed", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editNeed({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("need/update", params, { Authorization: rootState.auth.token })
          .then((res) => {
            if (res.data.success == true) {
              commit("editNeed", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteNeed({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("need/delete", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteNeed", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addPhoto({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("need/add-photo", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getLocation({ state }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${params}&key=${state.apiKey}`
          )
          .then((res) => {
            if (res.data.results.length) {
              resolve(res.data.results[0].geometry.location);
            } else {
              reject("No locations");
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getLocationWithAddress({ state }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${params}&key=${state.apiKey}`
          )
          .then((res) => {
            if (res.data.results.length) {
              resolve(res.data.results[0]);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchAllShifts({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("shift/all", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addShift({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("shift/add", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editShift({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("shift/update", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteShift({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("shift/delete", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    respondShift({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("response/add", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    onUpdateResponseNote({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("response/note", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    cancelResponse({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("response/cancel", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
