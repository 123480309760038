var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (_vm.profileType == 'Volunteer' || _vm.profileType == 'Agency') &&
      _vm.profile &&
      _vm.profile._id &&
      _vm.$route.name != 'chat' &&
      _vm.$route.name != 'chat-details' &&
      _vm.$route.name != 'login' &&
      _vm.$route.name != 'facebook-callback' &&
      _vm.$route.name != 'instagram-callback' &&
      _vm.$route.name != 'linkedin-callback' &&
      _vm.$route.name != 'twitter-callback' &&
      _vm.$route.name != 'tiktok-callback'
  )?_c('div',{staticClass:"aichat-bottom",staticStyle:{"position":"fixed","bottom":"0","right":"0","z-index":"2"}},[_c('v-navigation-drawer',{staticClass:"rounded-lg rounded-b-0 d-flex justify-center",style:(_vm.mini ? 'width: 288px; height: 47px' : 'height: 500px; width: 350px;'),attrs:{"mini-variant":_vm.mini,"permanent":"","color":"#084468","dark":""},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-grow white--text pt-3 pl-5 app-medium-font cursor-pointer",on:{"click":function($event){_vm.mini = !_vm.mini}}},[_vm._v(" AI Chat Support ")]),_c('v-btn',{staticClass:"mt-2 mr-1",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mini ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1),(!_vm.mini)?_c('v-divider'):_vm._e(),(!_vm.mini)?_c('div',{directives:[{name:"chat-scroll",rawName:"v-chat-scroll",value:({ always: false, smooth: false, notSmoothOnInit: true }),expression:"{ always: false, smooth: false, notSmoothOnInit: true }"}],staticClass:"pa-3 flex-grow overflow-auto",attrs:{"id":"scroll-target"}},[_vm._l((_vm.messages),function(message,index){return _c('div',{key:index,staticClass:"bubble-container",class:{ myMessage: message.role === 'user' }},[_c('div',{class:{
            'd-flex': true,
            'flex-column': true,
            'first-message': message.index == 0,
          },style:(message.role === 'assistant'
              ? 'align-items: flex-start;'
              : 'align-items: flex-end;')},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"max-width":"90%","position":"relative"}},[_c('div',{staticClass:"bubble"},[_c('div',{staticClass:"message text-block"},[_vm._v(" "+_vm._s(message.content)+" ")])])])])])}),(_vm.sending)?_c('div',{staticClass:"d-flex flex-row",staticStyle:{"margin-bottom":"19px","margin-left":"80px","font-size":"14px","color":"lightgray"}},[_c('div',{staticClass:"ml-1"},[_c('v-img',{attrs:{"src":require("@/assets/gif/typing.gif"),"contain":"","height":"20","width":"30"}})],1)]):_vm._e()],2):_vm._e(),(!_vm.mini)?_c('v-divider'):_vm._e(),(!_vm.mini)?_c('div',{staticClass:"d-flex"},[_c('v-textarea',{ref:"message",staticClass:"message-text-input",attrs:{"placeholder":"Enter your message","flat":"","solo":"","hide-details":"","rows":"1","auto-grow":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.sendMessage.apply(null, arguments)}},model:{value:(_vm.messageText),callback:function ($$v) {_vm.messageText=$$v},expression:"messageText"}}),_c('v-btn',{staticClass:"ml-2 mt-1 mr-1",attrs:{"icon":"","loading":_vm.sending},on:{"click":_vm.sendMessage}},[_c('v-icon',{staticClass:"mt-1",attrs:{"size":"20","color":"white"}},[_vm._v("mdi-send")])],1)],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }