import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    donations: [],
  },
  getters: {
    getAllDonations(state) {
      return state.donations;
    },
  },
  mutations: {
    setDonations(state, data) {
      state.donations = data;
    },
    addDonation(state, data) {
      const item = state.donations.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.donations.unshift(data);
      }
    },
    editDonation(state, data) {
      const item = state.donations.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.donations.push(data);
      }
    },
    deleteDonation(state, data) {
      state.donations = state.donations.filter((q) => q._id !== data._id);
    },
  },
  actions: {
    fetchAllDonations({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("donations/all")
          .then((res) => {
            if (res.data.success == true) {
              commit("setDonations", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getDonation({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("donations/get", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addDonation({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("donations/add", params, {
            Authorization: rootState.auth.token,
          })
          .then((res) => {
            if (res.data.success == true) {
              commit("addDonation", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editDonation({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("donations/update", params, {
            Authorization: rootState.auth.token,
          })
          .then((res) => {
            if (res.data.success == true) {
              commit("editDonation", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteDonation({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("donations/delete", params, {
            Authorization: rootState.auth.token,
          })
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteDonation", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    createPaymentIntent({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("donations/create-intent", param, {
            Authorization: rootState.auth.token,
          })
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getPaymentIntent({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("donations/get-intent", param, {
            Authorization: rootState.auth.token,
          })
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
