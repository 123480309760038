<template>
  <div class="w-100 min-width-200 mx-0 d-flex flex-column align-center">
    <div class="d-flex flex-column w-100 pt-6 px-5 pb-5">
      <v-row>
        <v-col cols="12" sm="8">
          <welcome-card />
          <v-row>
            <v-col cols="6" class="pt-6">
              <business-card />
            </v-col>
            <v-col cols="6" class="pt-6">
              <ads-card />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="4">
          <agency-card />
        </v-col>
      </v-row>
    </div>
    <div class="px-5 pb-5 w-100"><need-card /></div>
    <div class="d-flex flex-column w-100 px-5 pb-5">
      <v-row>
        <v-col cols="12" sm="8">
          <events-card />
        </v-col>
        <v-col cols="12" sm="4">
          <volunteer-card />
        </v-col>
      </v-row>
    </div>

    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import WelcomeCard from "./AdminWelcomeCard.vue";
import BusinessCard from "./AdminHomeBusinessCard.vue";
import AdsCard from "./AdminHomeAdsCard.vue";
import AgencyCard from "./AdminHomeAgencyCard.vue";
import NeedCard from "./AdminHomeNeedCard.vue";
import EventsCard from "./AdminHomeEventsCard.vue";
import VolunteerCard from "./AdminHomeVolunteerCard.vue";

export default {
  name: "Home",
  components: {
    WelcomeCard,
    BusinessCard,
    AdsCard,
    AgencyCard,
    NeedCard,
    EventsCard,
    VolunteerCard,
  },
  data() {
    return {
      snackbar: false,
      errorMessage: null,
    };
  },
  methods: {
    ...mapActions("auth", {
      getHomeInfo: "getHomeInfo",
    }),
    goToDetails(route) {
      if (route) {
        this.$router.push({ name: route });
      }
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      type: "type",
    }),
    ...mapGetters({
      conversations: "chat/getConversations",
    }),
  },
  created() {
    if (this.type != "Admin") {
      this.$router.push({ name: "home" });
    }
    this.getHomeInfo()
      .then((res) => {
        // console.log(res);
        this.homeData = res;
        // this.menu = this.menu.map((line) => {
        //   line.data = line.data.map((d) => {
        //     if (d.title == "Volunteers") d.count = res.volunteers;
        //     if (d.title == "Needs") d.count = res.need;
        //     if (d.title == "Locations") d.count = res.location;
        //     if (d.title == "Chat") d.count = this.conversations.length;
        //     if (d.title == "Agencies") d.count = res.agencies;
        //     if (d.title == "Businesses") d.count = res.business;
        //     if (d.title == "Ads") d.count = res.trailer;
        //     if (d.title == "Awards") d.count = res.awards;
        //     if (d.title == "Notifications") d.count = res.notifications;
        //     if (d.title == "Events") d.count = res.events;
        //     return d;
        //   });
        //   return line;
        // });
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style scoped>
.home_screen {
  background-color: #fff4de;
  height: 100%;
  padding: 20px;
}
.item_title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
}
.count_label {
  color: white;
  font-family: "Poppins-SemiBold";
  font-size: 18px;
}
.users_image_section {
  padding-bottom: 20px;
  padding-top: 30px;
  padding-left: 40px;
  justify-content: center;
  max-width: 200px;
}
</style>
