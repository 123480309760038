<template>
  <keep-alive>
    <v-container
      fluid
      class="d-flex flex-column h-100 pa-0 min-height-inherit"
      v-if="type == 'Admin'"
    >
      <div class="d-flex flex-row h-100 pa-0 min-height-inherit">
        <side-menu class="hidden-sm-and-down" />
        <content-section />
      </div>
    </v-container>
  </keep-alive>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import SideMenu from "./AdminHomeSideMenu";
import ContentSection from "./AdminHomeContent.vue";

export default {
  name: "Home",
  components: {
    SideMenu,
    ContentSection,
  },
  data() {
    return {
      homeData: {},
    };
  },
  methods: {
    ...mapActions("auth", {
      getHomeInfo: "getHomeInfo",
    }),
    goToDetails(route) {
      if (route) {
        this.$router.push({ name: route });
      }
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      type: "type",
    }),
    ...mapGetters({
      conversations: "chat/getConversations",
    }),
  },
  created() {
    if (this.type != "Admin") {
      this.$router.push({ name: "home" });
    }
    this.getHomeInfo()
      .then((res) => {
        console.log(res);
        this.homeData = res;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style scoped>
.home_screen {
  background-color: #fff4de;
  height: 100%;
  padding: 20px;
}
.item_title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
}
.count_label {
  color: white;
  font-family: "Poppins-SemiBold";
  font-size: 18px;
}
.users_image_section {
  padding-bottom: 20px;
  padding-top: 30px;
  padding-left: 40px;
  justify-content: center;
  max-width: 200px;
}
</style>
