<template>
  <div class="left-section">
    <v-navigation-drawer
      permanent
      width="300"
      color="#084468"
      dark
      height="auto"
    >
      <v-list>
        <v-list-item-group v-model="selectedItem">
          <v-list-item
            v-for="([icon, text, name], i) in menuItems"
            :key="i"
            link
            @click="onOpenMenu(name)"
            :ref="`menuItem-${i}`"
          >
            <v-list-item-icon class="ml-5">
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      drawer: true,
      mini: false,
      isSelecting: false,
      tokenDialog: false,
      menuItems: [
        ["mdi-view-dashboard", "Dashboard", "admin-home"],
        ["mdi-account-group", "Volunteers", "admin-volunteers"],
        ["mdi-domain", "Agencies", "admin-agencies"],
        ["mdi-marker-check", "Needs", "agency-need-feeds"],
        ["mdi-calendar-text", "Events", "events"],
        ["mdi-message-fast", "Send message", "admin-message"],
        ["mdi-map", "Map", "need-map"],
        // ["mdi-police-badge", "Badges"],
        ["mdi-trophy", "Awards", "awards"],
        ["mdi-briefcase-variant", "Businesses", "admin-business"],
        ["mdi-advertisements", "Ads", "admin-ads"],
        ["mdi-post", "Blogs", "blog-feeds"],
        ["mdi-alert-octagon", "Reported", "reported"],
        ["mdi-checkbox-blank-badge", "Notifications", "notifications"],
        ["mdi-cog", "Settings", "settings"],
        ["mdi-account", "My profile", "admin-profile"],
        ["mdi-message", "Chat", "chat"],
        ["mdi-logout", "Log out", "logout"],
      ],
      selectedItem: -1,
    };
  },
  methods: {
    ...mapActions({
      onLogout: "auth/onLogout",
    }),
    goToProfile() {
      this.$router.push({ name: "admin-profile" });
    },
    onOpenMenu(name) {
      if (name == "logout") {
        this.onLogout().then(() => {
          this.$router.push({ name: "landing" });
        });
      } else if (this.$route.name != name) {
        this.$router.push({ name });
      }
    },
  },
  mounted() {
    for (let i = 0; i < this.menuItems.length; i++) {
      if (this.menuItems[i][2] == this.$route.name) {
        this.selectedItem = i;

        if (i > 9) {
          this.$nextTick(() => {
            setTimeout(() => {
              const selectedItemRef = this.$refs[
                `menuItem-${this.selectedItem}`
              ];
              if (selectedItemRef && selectedItemRef[0]) {
                selectedItemRef[0].$el.scrollIntoView();
              }
            }, 0);
          });
        }
        break;
      }
    }
  },
};
</script>
<style scoped>
.left-section {
  max-height: 0;
  overflow-y: hidden;
  position: sticky;
  min-height: inherit;
  overflow-x: hidden;
  max-width: 300px;
  min-width: 300px;
  width: 300px;
  top: 56px;
  background: #084468;
}
.left-section:hover {
  overflow-y: auto;
}
.left-section::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

/* Track */
.left-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.left-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.left-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
