<template>
  <v-card
    color="white"
    elevation="8"
    class="card_home rounded-xl d-flex"
    @mouseover="active = true"
    @mouseleave="active = false"
  >
    <div class="w-50">
      <v-card-title>
        Welcome Admin!<br /><br />
        The team "Volunteers of the world" is ready to be awarded.
      </v-card-title>
      <v-card-text>
        They have completed a total of 500 hours.
      </v-card-text>
    </div>
    <div class="w-50 d-flex">
      <v-img
        :src="require('@/assets/svg/welcome_team.svg')"
        width="450"
        height="280"
        contain
      />
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "Volunteer",
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    goToProfile() {},
  },
};
</script>
<style scoped>
.card_home {
  min-height: 100px;
  background-image: linear-gradient(#efecf4, #f6f4f9, #fbfafc);
  bottom: 0;
  width: 100%;
  /* height: 100%; */
  margin: 0px;
  padding: 0px;
}
.item_title {
  color: #472583;
  font-family: "Poppins-Medium";
  font-size: 20px;
  margin-left: 5px;
}
.item_count {
  color: white;
  background-color: #ea1818;
  font-family: "Poppins-Medium";
  font-size: 20px;
}
</style>
